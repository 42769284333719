import React from 'react'
import { Link } from 'gatsby'
// import logo from '../img/logo.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (  
      <div>
        <nav id="header" className="navbar" role="navigation" aria-label="main navigation">
          <div className="inner">
            <div className="navbar-brand">
              <Link to="/" className="logo">
                Phân bón hữu cơ Úc
              </Link>
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>                         
            </div>
            <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
              <div className="navbar-end has-text-centered">
              <div className="navbar-item">
                  <Link to="/blog">
                    Bài viết
                  </Link>
                </div>
                <div className="navbar-item">
                  <Link to="/about">
                    Giới thiệu
                  </Link>
                </div>
              </div>
            </div>   
          </div>          
        </nav>        
      </div>              
      // <nav
      //   className="navbar is-transparent"
      //   role="navigation"
      //   aria-label="main-navigation"
      // >
      //   <div className="container">
      //     <div className="navbar-brand">
      //       <Link to="/" className="navbar-item" title="Logo">
      //         <img src={logo} alt="Phân bón hữu cơ Úc" style={{ width: '300px' }} />
      //       </Link>
      //       {/* Hamburger menu */}
      //       <div
      //         className={`navbar-burger burger ${this.state.navBarActiveClass}`}
      //         data-target="navMenu"
      //         onClick={() => this.toggleHamburger()}
      //       >
      //         <span />
      //         <span />
      //         <span />
      //       </div>
      //     </div>
      //     <div
      //       id="navMenu"
      //       className={`navbar-menu ${this.state.navBarActiveClass}`}
      //     >
      //       <div className="navbar-end has-text-centered">
      //       <Link className="navbar-item" to="/about">
      //           GIỚI THIỆU
      //         </Link>
      //         <Link className="navbar-item" to="/products">
      //           SẢN PHẨM
      //         </Link> 
      //         <Link className="navbar-item" to="/blog">
      //           BÀI VIẾT
      //         </Link>             
      //         <Link className="navbar-item" to="/contact">
      //           LIÊN HỆ
      //         </Link>              
      //       </div>            
      //     </div>
      //   </div>
      // </nav>
    )
  }
}

export default Navbar
