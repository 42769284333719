import React from 'react';
import PropTypes from 'prop-types';
import {
	FacebookShareButton
} from 'react-share';

const BlogShare = ({ socialConfig, tags }) => (
	<div className="post-social container">
    <span>Chia sẻ bài viết</span>
    <ul className="post-list">
      <li className="post-item">
        <FacebookShareButton url={socialConfig.config.url} className="button-social" >
        <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-facebook"
              >
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
              </svg>
            </span>
        </FacebookShareButton>		
      </li>      
    </ul>		
	</div>
);

BlogShare.propTypes = {
	socialConfig: PropTypes.shape({		
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
BlogShare.defaultProps = {
	tags: [],
};

export default BlogShare;