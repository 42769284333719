import React from 'react'

const Footer = class extends React.Component {
   componentDidMount(){
     let contactForm = document.querySelector("#footer form");      
     contactForm.addEventListener("submit", e => {
       e.preventDefault();
       const formData = new FormData(contactForm);
       fetch("/", {
         method: "POST",
         headers: {
           "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
           "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
         },
         body: new URLSearchParams(formData).toString()
       })
       .then(res => {         
         if (res) {
           alert("Cảm ơn bạn đã liên hệ với chúng tôi. Chúng tôi đánh giá cao các câu hỏi và phản hồi của bạn và sẽ trả lời nhanh nhất có thể. Nếu bạn không nhận được tin từ chúng tôi sớm, vui lòng kiểm tra thư mục thư rác của bạn.");
         }
       });
     });
   }
   render() {
     return (
       <section id="footer">
	 			<div className="inner">
	 				<header>
	 					<h2>Liên lạc</h2>
	 				</header>
	 				<form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true">
            {/* <input type="hidden" name="form-name" value="contact"/>
            <p className="hidden">
              <label>Don't fill this out if you're human: <input name="bot-field" /></label>
            </p> */}
            <p>Quý vị muốn nhập khẩu phân hữu cơ của chúng tôi hoặc có những thắc mắc gì, xin vui lòng gởi thông tin và chúng tôi sẽ phản hồi nhanh</p>
	 					<div className="field half first">
	 						<label htmlFor="name">Tên của bạn</label>
	 						<input type="text" name="name" id="name" />
	 					</div>
	 					<div className="field half">
	 						<label htmlFor="email">Email</label>
	 						<input type="email" name="email" id="email" />
	 					</div>
	 					<div className="field">
	 						<label htmlFor="message">Lời nhắn</label>
	 						<textarea name="message" id="message" rows="6"></textarea>
	 					</div>
             <div data-netlify-recaptcha="true"></div>
	 					<ul className="actions">
	 						<li>
                 <button type="submit" className="alt">Gởi tin nhắn</button>
               </li>
	 					</ul>
	 				</form>	 				
	 			</div>
	 		</section>
     )
   }  
}

export default Footer
