import React from 'react'
import {Helmet} from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './style/all.sass'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children }) => {  
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="phan huu co, phan bon huu co, phân hữu cơ, phân bón hữu cơ, phân hữu cơ Úc, phân bón hữu cơ Úc, phân hữu cơ chất lượng cao, phân bón hữu cơ chất lượng cao, phân hữu cơ Úc chất lượng cao, phân bón hữu cơ Úc chất lượng cao, nông nghiệp bền vững, nông nghiệp Việt Nam bền vững, phân hữu cơ ngoại nhập, phân bón hữu cơ ngoại nhập, phân bón nhập khẩu, phân nhập khẩu, phân hữu cơ nhập khẩu, phân bón hữu cơ nhập khẩu" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        
        <meta name="theme-color" content="#135F38" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
              
        
      </Helmet>      		
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
